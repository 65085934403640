.App {
  text-align: center;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}


.status-bar {
  background-color: #d9e6f1; /* Light grey background */
  height: 20px; /* Set a height for the status bar */
  width: 100%; /* Full width */
  border: 1px solid #ccc; /* Optional: adds a border around the status bar */
}

.status-bar-fill {
  background-color: #4285F4;
  height: 100%; /* Fill the height of the parent */
  transition: 1000ms;

}

.App-header {
  background-color: white;
  font-weight: bold;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: black;
  margin: 20px
}

.App-form-section{
  background-color: white;
  min-height: 5vh;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  color: black;

}

.App-option-button {
  background-color: white;
  color: black;
  font-weight: bold;
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 500px; /* Full width */
  padding: 20px;
  margin: 10px;
  height: 60px;
  transition: 100ms;
  &:hover {
    background-color: rgb(254, 245, 215);
    border: 3px solid black;
  }
}

.App-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 500px; /* Full width */
  padding: 20px;
  margin: 10px;
  background-color: #f5f3f3;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.App-navigation{
  background-color: white;
  position: relative;
  top: 10px;
}

.App-back-button{
  background-color: white;
  color: black;
  font-weight: bold;
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  margin-right: 10px;
  width: 200px; /* Set the desired widthh */
  height: 60px; /* Set the desired height */
  transition: all 0.3s; /* Add transition for smooth hover effect */
  &:hover {
    background-color: lightgray;
}
}

.App-next-button, .App-submit-button {
  background-color: rgb(247, 211, 93);
  color: black;
  font-weight: bold;
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  margin-right: 10px;
  width: 200px; /* Set the desired width */
  height: 60px; /* Set the desired height */
  transition: all 0.3s; /* Add transition for smooth hover effect */
  &:hover {
    background-color: rgb(235, 184, 17);
}
}

.App-disclaimer{
  color: black;
  font-size: 12px;
}